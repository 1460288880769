import React from 'react';


const Header = () => {
    return (
        <nav className="navbar is-primary"  role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item">
                    <h1 className='title p-2' color='green'>
                        Hacker Job Serch
                    </h1>
                </a>
            </div>
        </nav>
    )
}


export default Header;
